<template>
  <div>
    <van-cell-group>
      <van-field v-model="Name" label="企业名称" required readonly="readonly" />
      <van-field
        v-model="dataFrom.ETypeName"
        label="发布类型"
        required
        placeholder="请选择发布类型"
        @click="typeshow = true"
        readonly="readonly"
      />
      <van-popup v-model="typeshow" position="bottom" get-container="body">
        <van-picker
          show-toolbar
          :columns="typelist"
          value-key="value"
          @cancel="typeshow = false"
          @confirm="ontypeshow"
        >
        </van-picker>
      </van-popup>
      <van-field
        v-model="dataFrom.Title"
        label="材料名称"
        required
        placeholder="请输入材料名称"
      />
      <van-field
        v-model="dataFrom.Model"
        label="规模型号"
        required
        placeholder="请输入材料规模"
      />
      <van-field
        v-model="dataFrom.Amount"
        label="资源数量"
        required
        placeholder="请输入材料数量"
      />
      <van-field
        v-model="dataFrom.Remark"
        rows="3"
        autosize
        label="备注"
        type="textarea"
        placeholder="请输入备注内容"
      />
    </van-cell-group>
    <button class="buttonstyle" @click="setDataFrom()">提 交</button>
  </div>
</template> 
<script>
import Vue from "vue";
import { Dialog } from "vant";
Vue.use(Dialog);
import { getaccToken, setaccToken, removeaccToken } from "@/utils/auth";
import { WeSaveEntElement } from "@/api/Hyapi";
export default {
  data() {
    return {
      typeshow: false,
      typelist: [
        { index: 1, value: "供给资源" },
        { index: 2, value: "需求资源" },
      ],
      dataFrom: {
        accToken: "", //企业用户访问令牌，
        EEId: 0, //主键Id，
        EType: "", //供需类型：1供应、2需求（非空），
        Title: "", //名称（非空），
        Model: "", //规格型号，
        Amount: "", //数量(非空)
        Status: 1, //状态：1上架、2下架（非空） ，
        Remark: "", //备注
        IsVerify:"0",
      },
      Name: "",
    };
  },
  mounted() {
    this.getToken = JSON.parse(getaccToken());
    this.Name = this.getToken.uName;
    this.dataFrom.accToken = this.getToken.token;
  },
  methods: {
    // 提交
    setDataFrom() {
      WeSaveEntElement(this.dataFrom).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Dialog.alert({
            message: "提交成功!",
          }).then(() => {
            this.dataFrom = {
              accToken: this.getToken.token, //企业用户访问令牌，
              EEId: 0, //主键Id，
              EType: "", //供需类型：1供应、2需求（非空），
              Title: "", //名称（非空），
              Model: "", //规格型号，
              Amount: "", //数量(非空)
              Status: 1, //状态：1上架、2下架（非空） ，
              Remark: "", //备注
            };
          });
        } else {
          Dialog.alert({
            message: "提交失败," + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
    ontypeshow(e) {
      //供应类型选择器
      this.dataFrom.ETypeName = e.value;
      this.dataFrom.EType = e.index;
      this.typeshow = false;
    },
  },
};
</script>